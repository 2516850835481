import { datadogRum } from '@datadog/browser-rum';

import '~/common-styles';

import { getEnv } from 'shared/dist/lib/env';

import config, { Environment } from './Config';

import './styles/core.scss';

// We want to run this asap because we want it to start timing for us
if (
  config.DATADOG_APP_ID &&
  config.DATADOG_CLIENT_TOKEN &&
  getEnv('REACT_APP_ENV') !== 'local'
) {
  datadogRum.init({
    applicationId: config.DATADOG_APP_ID,
    clientToken: config.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'stargate',
    env: getEnv('REACT_APP_ENV'),
    version: getEnv('REACT_APP_VERSION'),
    sessionSampleRate: 100,
    trackViewsManually: true,
    defaultPrivacyLevel: 'allow',
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });

  if (
    getEnv('NODE_ENV') === 'production' &&
    getEnv('REACT_APP_ENV') === Environment.PRODUCTION
  ) {
    datadogRum.startSessionReplayRecording();
  }
}

const [, orgUrl, ,] = window.location.pathname.split('/');

const publicPages = ['public-doc', 'cli'];

if (publicPages.some((path) => path === orgUrl)) {
  import('./apps/PublicApp');
} else {
  import('./apps/App');
}
